* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "PT Sans", sans-serif;
}

.home,
.getting-started,
.white-paper,
.sign-up {
	display: flex;
	height: 90vh;
	align-items: center;
	justify-content: center;
	font-size: 3rem;
}

.getting-started {
	background-color: gray;
	/* background-image: url("images/img-2.jpg"); */
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.white-paper {
	background-image: url("/src/images/img-1.jpg");
	background-position: center;
	background-size: fill;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.sign-up {
	background-image: url("/src/images/img-3.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}
