video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: -1;
}
/* remove background in hero-container for video background to take effect */
.hero-container {
	/* background: url("../images/img-home.jpg") center center/cover no-repeat; */
	background-color: #f7931a;
	height: 80vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
	object-fit: contain;
	justify-content: center;
}

.hero-container > h1 {
	color: #fff;
	font-size: 100px;
	margin-top: -10px;
}

.hero-container > h4 {
	color: #fff;
	font-size: 32px;
	margin-top: 8px;
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
}

h2 {
	color: white;
	font-size: 75px;
}
.hero-btns {
	margin-top: 32px;
}

.hero-btns .btn {
	margin: 6px;
}

.fa-play-circle {
	margin-left: 4px;
}

@media screen and (max-width: 960px) {
	.hero-container > h1 {
		color: #fff;
		font-size: 70vw;
		margin-top: -150px;
	}
}
@media screen and (max-width: 768px) {
	.hero-container > h1 {
		color: #fff;
		font-size: 50vw;
		margin-top: -100px;
	}
}
@media screen and (max-width: 768px) {
	.hero-container h2{
		color: white;
		font-size: 10vw;
		margin-top: -100px;
	}
	.hero-container h4{
		color: white;
		font-size: 6vw;
	}
}

.hero-container > h4 {
	font-size: 3vw;
}

.btn-mobile {
	display: block;
	text-decoration: none;
}

.btn {
	width: 100%;
}
