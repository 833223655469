.money-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	font-family: "Times New Roman", Times, serif;
}
.main-content-container{
	padding-bottom: 20px;
}
.money {
	text-align: center;
	font-size: 10vw;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #f7931a;
	color: white;
}
.main-content{
	font-size: 1.5vw;
	font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
	display: flex;
	justify-content: center;
	text-align: left;
	line-height: 3vw;
	margin-left: 15%;
	margin-right: 15%;
	padding-top: 2rem;
}

.content {
	font-size: 20px;
	font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
	width: 65%;
	display: flex;
	margin-left: 20%;
	justify-content: center;
	text-align: left;
	line-height: 1.6;
}

.definition-container > h2 {
	font-weight: bold;
	font-size: 40px;
	display: flex;
	justify-content: center;
	margin-left: 25%;
	margin-right: 25%;
	padding-top: 100px;
	font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
	color: #f7931a;
}
.sub-title{
	color: #f7931a;
	padding-top: 4rem;
		font-size: 4vw;
		display: flex;
		justify-content: center;
}
.break-line{
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: #f7931a;
}
.definition {
	font-size: 16px;
	display: flex;
	justify-content: space-around;
	margin-left: 25%;
	margin-right: 25%;
	padding-bottom: 25px;
	font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
	border-bottom: black solid 0.3px;
}

@media screen and (max-width: 768px) {
	.money-container h1{
		color: #f7931a;
		font-size: 10vw;
	}
	.money-container h2{
		color: #f7931a;
		font-size: 10vw;
		display: flex;
		justify-content: center;
	}

}